const units: { [index: string]: number } = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
}

export const getRelativeTime = (stringDate?: string) => {
  if (stringDate) {
    const date = new Date(stringDate)
    const now = new Date()
    const rtf = new Intl.RelativeTimeFormat("fr", {
      numeric: "auto",
    })

    const elapsed = date.getTime() - now.getTime()

    for (const unit in units) {
      if (Math.abs(elapsed) > units[unit] || unit == "second") {
        return rtf.format(
          Math.round(elapsed / units[unit]),
          unit as Intl.RelativeTimeFormatUnit
        )
      }
    }
  }
  return null
}
