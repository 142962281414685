import { Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react"
import React from "react"

const GroupActivityItemSkeleton = () => (
  <Flex
    alignItems="center"
    backgroundColor="white"
    flexDirection={{ base: "column", md: "row" }}
    width="100%"
    py={7}
    px={5}
    gap={4}
  >
    <SkeletonCircle />
    <Flex flex="1" justifyContent="space-between">
      <Skeleton width={150} height={4} />
      <Skeleton width={100} height={4} />
    </Flex>
  </Flex>
)

export default GroupActivityItemSkeleton
