import client from "@/api/client"
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons"
import { Flex, IconButton } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PageTitle from "../Layout/PageTitle"
import NewsListItem from "./NewsListItem"
import NewsListCardSkeleton from "./Skeletons/NewsListCardSkeleton"

const NewsList = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useQuery("get-articles", () =>
    client.articles.getArticles({
      //@ts-ignore
      query: {
        populate: "*",
      },
      "populate[cover]": "*",
      "pagination[page]": 0,
      "pagination[pageSize]": 6,
      sort: "publishedAt:desc",
    })
  )

  const news = data?.data?.data || []

  if (news.length === 0) {
    return null
  }

  return (
    <>
      {isLoading && !news && <NewsListCardSkeleton />}
      {!isLoading && !!news?.length && (
        <>
          <PageTitle
            title={t("group.news.my_news.title")}
            stackProps={{
              flexDirection: "row",
            }}
            extraActions={
              <>
                <Flex justifyContent="space-between">
                  <IconButton
                    aria-label="prev"
                    variant="ghost"
                    icon={<ArrowBackIcon fontSize={40} />}
                    className="slider-news-prev"
                  />
                  <IconButton
                    aria-label="next"
                    variant="ghost"
                    icon={<ArrowForwardIcon fontSize={40} />}
                    className="slider-news-next"
                  />
                </Flex>
              </>
            }
          />
          <Swiper
            navigation={{
              nextEl: ".slider-news-next",
              prevEl: ".slider-news-prev",
              disabledClass: "swiper-button-disabled",
            }}
            slidesPerView={1}
            breakpoints={{
              576: {
                slidesPerView: 2,
              },
            }}
            modules={[Navigation]}
            spaceBetween={20}
          >
            {news?.map((item, index) => (
              <SwiperSlide key={index}>
                <NewsListItem
                  key={index}
                  id={item?.id}
                  title={item?.attributes?.title}
                  publishedAt={item?.attributes?.publishedAt}
                  url={item?.attributes?.cover?.data?.attributes?.url}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </>
  )
}
export default NewsList
