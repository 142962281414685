import { Flex, SimpleGrid } from "@chakra-ui/react"
import { useTranslation } from "next-i18next"
import Head from "next/head"
import ActivitiesList from "./Activities/ActivitiesList"
import GroupSlider from "./Groups/GroupSlider"
import PageTitle from "./Layout/PageTitle"
import NewsList from "./News/NewsList"

const ConnectedHome = () => {
  const { t } = useTranslation()

  return (
    <>
      <Head>
        <title>{t("seo.home")}</title>
      </Head>
      <SimpleGrid
        columns={{ base: 1, xl: 2 }}
        spacing={{ base: 6, xl: 10 }}
        p={{ base: 4, xl: 10 }}
      >
        <Flex gap={6} flexDirection="column" order={{ base: 2, xl: 1 }} pt={2}>
          <PageTitle title={t("activity.title")} pb="10px" />
          <ActivitiesList />
        </Flex>
        <Flex flexDirection="column" gap={10} order={{ base: 1, xl: 2 }}>
          <Flex flexDirection="column" gap={4}>
            <NewsList />
          </Flex>
          <Flex flexDirection="column">
            <GroupSlider />
          </Flex>
        </Flex>
      </SimpleGrid>
    </>
  )
}

export default ConnectedHome
