import client from "@/api/client"
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons"
import { Box, Button, Flex, IconButton } from "@chakra-ui/react"
import Link from "next/link"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PageTitle from "../Layout/PageTitle"
import GroupListItem from "./GroupListItem"

const GroupSlider = () => {
  const { t } = useTranslation()
  const { data } = useQuery("get-groups", () =>
    client.groups
      .getGroups({
        "pagination[page]": 0,
        "pagination[pageSize]": 10,
        sort: "updatedAt:desc",
        //@ts-ignore
        "populate[reviews]": "*",
        "populate[memberships][populate][users_permissions_user][populate]":
          "picture",
      })
      .then((response) => response.data)
  )

  return (
    <>
      <PageTitle
        title={t("group.title")}
        stackProps={{
          flexDirection: "row",
        }}
        extraActions={
          <>
            <Flex justifyContent="space-between">
              <IconButton
                aria-label="prev"
                variant="ghost"
                icon={<ArrowBackIcon fontSize={40} />}
                className="slider-groups-prev"
              />
              <IconButton
                aria-label="next"
                variant="ghost"
                icon={<ArrowForwardIcon fontSize={40} />}
                className="slider-groups-next"
              />
            </Flex>
          </>
        }
      />
      <Box my={4} width="100%" textAlign="right">
        <Swiper
          navigation={{
            nextEl: ".slider-groups-next",
            prevEl: ".slider-groups-prev",
            disabledClass: "swiper-button-disabled",
          }}
          slidesPerView={1}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            1280: {
              slidesPerView: 1,
            },
            1500: {
              slidesPerView: 2,
            },
          }}
          modules={[Navigation]}
          spaceBetween={20}
        >
          {data?.data &&
            Array.isArray(data.data) &&
            data.data.map((group, index) => (
              <SwiperSlide key={index}>
                <GroupListItem key={group?.id} group={group} />
              </SwiperSlide>
            ))}
        </Swiper>
        <Button
          as={Link}
          size="sm"
          aria-label="groups"
          href="/groups"
          variant="brand"
          width={{ base: "100%", md: "auto" }}
        >
          {t("group.all_groups")}
        </Button>
      </Box>
    </>
  )
}

export default GroupSlider
