const ArrowBack = (props: any) => {
  return (
    <svg
      width="23"
      height="42"
      viewBox="0 0 23 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.0001 1L2 21.0001L22.0001 41.0001"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ArrowBack
