import { formatCurrentDate } from "@/utils/date"
import { getImageUrl } from "@/utils/images"
import { Image, Link, Text, VStack } from "@chakra-ui/react"
import NextLink from "next/link"

const NewsListItem = ({
  id,
  url,
  title,
  publishedAt,
}: {
  id?: number
  url?: string
  title?: string
  publishedAt?: string
}) => {
  return (
    <Link
      as={NextLink}
      href={`/news/${id}`}
      width="100%"
      _hover={{ textDecoration: "none" }}
    >
      <VStack
        bg="white"
        boxShadow="lg"
        p={2}
        height="100%"
        alignItems="flex-start"
      >
        <Image
          alt={title}
          height={120}
          objectFit="cover"
          width="100%"
          src={getImageUrl(url)}
        />
        {publishedAt && (
          <Text width="100%" fontSize="xs" textAlign="left" color="gray.500">
            {formatCurrentDate(publishedAt)}
          </Text>
        )}
        <Text
          pb={4}
          width="100%"
          fontSize="sm"
          textAlign="left"
          wordBreak="break-word"
          noOfLines={4}
          fontFamily="GTMaru"
          fontWeight="bold"
        >
          {title}
        </Text>
      </VStack>
    </Link>
  )
}

export default NewsListItem
