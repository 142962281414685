import ArrowBack from "@/svg/arrowBack"
import {
  ActivityListResponseDataItem,
  ReviewResponseDataObject,
} from "@/typings/api"
import { getRelativeTime } from "@/utils/relativeTime"
import { Box, Flex, HStack, Link, Text } from "@chakra-ui/react"
import { useTranslation } from "next-i18next"
import NextLink from "next/link"
import UserAvatar, { getUserName } from "../UserAvatar/UserAvatar"

const getActivityLink = (activity: ActivityListResponseDataItem) => {
  const basePath = `/groups/${activity?.attributes?.group?.data?.id}/reviews/${activity?.attributes?.review?.data?.id}`

  switch (activity?.attributes?.type) {
    case "GROUP_REVIEW":
    case "GROUP_RECOMMENDATION":
      return basePath
    case "GROUP_SUMMARY":
      return `${basePath}?view=summary`
    case "GROUP_REVIEW_MESSAGE":
      return `${basePath}?view=comments`
    case "GROUP_MESSAGE":
      return `/groups/${activity.attributes?.group?.data?.id}?index=1`
    default:
      return "/"
  }
}

const getActivityCaption = (
  activity: ActivityListResponseDataItem,
  t: (key: string, options?: object) => string,
  bookTitle?: string
) => {
  switch (activity?.attributes?.type) {
    case "GROUP_REVIEW":
      return t("group.activities.captions.group_review", { bookTitle })
    case "GROUP_RECOMMENDATION":
      return t("group.activities.captions.group_recommendation", { bookTitle })
    case "GROUP_SUMMARY":
      return t("group.activities.captions.group_summary", { bookTitle })
    case "GROUP_REVIEW_MESSAGE":
      return t("group.activities.captions.group_review_message", { bookTitle })
    case "GROUP_MESSAGE":
      return t("group.activities.captions.group_message")
    default:
      return ""
  }
}

const getActivityBadge = (activity: ActivityListResponseDataItem) => {
  switch (activity?.attributes?.type) {
    case "GROUP_REVIEW":
      return {
        label: "Fiche de lecture",
        color: "yellow.100",
      }
    case "GROUP_RECOMMENDATION":
      return {
        label: "Recommandation",
        color: "green.100",
      }
    case "GROUP_SUMMARY":
      return {
        label: "Résumé",
        color: "purple.100",
      }
    case "GROUP_REVIEW_MESSAGE":
      return {
        label: "Commentaire",
        color: "orange.100",
      }
    case "GROUP_MESSAGE":
      return {
        label: "Chat",
        color: "blue.100",
      }
    default:
      return {
        label: "",
        color: "",
      }
  }
}

const GroupActivityItem = ({
  activity,
  withGroupName = false,
}: {
  activity: ActivityListResponseDataItem
  withGroupName?: boolean
}) => {
  const { t } = useTranslation()
  const review = activity?.attributes?.review?.data as ReviewResponseDataObject
  const author = activity?.attributes?.author?.data?.attributes
  const createdAt = activity?.attributes?.createdAt
  const bookTitle = review?.attributes?.book?.data?.attributes?.title
  //@ts-ignore
  const groupName = activity?.attributes?.group?.data?.attributes?.name

  return (
    <Link
      href={getActivityLink(activity)}
      as={activity.attributes?.type === "GROUP_MESSAGE" ? "a" : NextLink}
      _hover={{ textDecoration: "none" }}
      width="100%"
    >
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        width="100%"
        p={4}
        backgroundColor="white"
        gap={4}
      >
        <Flex
          flexDirection="row"
          alignItems={{ base: "flex-start", md: "center" }}
          flex="1"
          fontSize="sm"
          gap={3}
        >
          <HStack spacing={4}>
            <UserAvatar
              user={{
                ...author,
                //@ts-ignore object type
                picture: author?.picture?.data?.attributes,
              }}
            />
          </HStack>
          <Flex flexDirection="column">
            <Box
              textAlign="left"
              fontSize="1em"
              fontWeight="normal"
              textOverflow="ellipsis"
              dangerouslySetInnerHTML={{
                __html: `<b>${getUserName(author)}</b> ${getActivityCaption(
                  activity,
                  t,
                  bookTitle
                )}`,
              }}
            />
            <Text color="gray" fontWeight="normal">
              {createdAt && getRelativeTime(createdAt)}
            </Text>
          </Flex>
        </Flex>

        <Flex
          gap={{ base: 2, md: 6 }}
          flexDirection={{ base: "row", md: "column" }}
          alignItems={{ base: "center", md: "flex-end" }}
          justifyContent={{ base: "flex-end", md: "space-between" }}
          color="lightGray"
          fontSize="sm"
        >
          <Box
            bg={getActivityBadge(activity)?.color}
            fontSize="11px"
            color="black"
            fontWeight="normal"
            px={2}
            py={1}
            borderRadius="sm"
          >
            {getActivityBadge(activity)?.label}
          </Box>
          <Flex flexDirection="row" alignItems="center">
            {withGroupName && <Text color="gray">Dans {groupName}</Text>}
            <Box transform="scaleX(-1)">
              <ArrowBack height="14" />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  )
}

export default GroupActivityItem
