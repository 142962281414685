import client from "@/api/client"
import { Box, Button, VStack } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useInfiniteQuery } from "react-query"
import GroupActivityItem from "../Groups/GroupActivityItem"
import GroupActivityItemSkeleton from "../Groups/Skeletons/GroupActivityItemSkeleton"

const ActivitiesList = () => {
  const { t } = useTranslation()
  const {
    data: response,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    `all-activities`,
    ({ pageParam = 0 }) =>
      client.activities.getActivities({
        populate: "author,group,review,review.book,author.picture",
        sort: "createdAt:desc",
        "pagination[page]": pageParam,
        "pagination[pageSize]": 10,
      }),
    {
      getNextPageParam: (response) => {
        const pageCount = response.data.meta?.pagination?.pageCount
        const currentPage = response.data.meta?.pagination?.page

        if (pageCount && currentPage && pageCount > currentPage) {
          return currentPage + 1
        }
      },
    }
  )

  return (
    <VStack spacing={4}>
      {isLoading ? (
        <>
          <GroupActivityItemSkeleton />
          <GroupActivityItemSkeleton />
        </>
      ) : (
        response?.pages.map((page) =>
          page.data.data?.map((activity) => (
            <GroupActivityItem
              key={activity.id}
              activity={activity}
              withGroupName
            />
          ))
        )
      )}
      {Boolean(response?.pages?.[0].data.meta?.pagination?.total === 0) && (
        <Box fontWeight="normal" fontSize="md">
          {t("group.activities.no_activities")}
        </Box>
      )}
      {hasNextPage && (
        <Button
          onClick={() => {
            fetchNextPage()
          }}
          fontWeight="normal"
          textTransform="lowercase"
          fontSize="lg"
          textDecoration="underline"
          variant="link"
        >
          {t("global.load_more")}
        </Button>
      )}
    </VStack>
  )
}

export default ActivitiesList
